//
// Override global variables
//

// Theme colors
$primary:       									#FE7E51;
$primary-hover:    									#be4a20;
$primary-light:    									#faebe9;
$primary-inverse:  									#ffffff;

$info:       									    #0288D1;
$info-hover:    									#03A9F4;
$info-light:    									#B3E5FC;
$info-inverse:  									#ffffff;

$success:       									#4CAF50;
$success-hover:    									#388E3C;
$success-light:    									#C8E6C9;
$success-inverse:  									#FFFFFF;
