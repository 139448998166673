/*! normalize.css v3.0.3 | MIT Licence | github.com/necolas/normalize.css */

//
// 1. Set default font family to sans-serif.
// 2. Prevent iOS and IE text size adjust after device orientation change,
//    without disabling user zoom.
// 3. Set 100% height for document body

html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-base;

    // Tablet Mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile Mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }

    -ms-text-size-adjust: 100%; // 2
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

body {
    display: flex;
    flex-direction: column;
    color: $body-color;
}

// Angular integration
router-outlet {
    display: none;
}

canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
