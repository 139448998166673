// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_power_uber/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_power_uber/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_power_uber/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_power_uber/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_power_uber/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_power_uber/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_power_uber/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_power_uber/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_power_uber/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_power_uber/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_power_uber/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_power_uber/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_power_uber/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

svg {
  pointer-events: none;
}

.fv-plugins-message-container {
  position: absolute;
}
.fv-plugins-message-container .fv-help-block {
  font-size: 10px;
  color: #f64e60 !important;
}
// .was-validated .form-control:invalid, .form-control.is-invalid {
//   border-color: #F64E60!important;
// }
// .was-validated .form-control:valid, .form-control.is-valid {
//   border-color: #3699FF!important;
// }

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  letter-spacing: 0rem !important;
}
.checkbox > span {
  background-color: #c9cedc;
}
.checkbox.disabled,
.checkbox > input:disabled ~ label {
  opacity: 0.65;
}
.phone-input input.PhoneInputInput {
  border: none;
  background: none;
}
.phone-input input.PhoneInputInput:focus {
  outline: none;
}
.b-dashed-top {
  border-top: 1px dashed #ccc;
}
.psnl-app:hover,
.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: #ebedf369 !important;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 25px;
  border: 3px dashed rgba(0, 0, 0, 0.15);
  border-radius: 0.42rem;
  transition: 0.2s;
  &.is-active {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.42rem;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
.file-drop-area-progress {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 25px;
  border-radius: 0.42rem;
  transition: 0.2s;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  z-index: 1;
  left: 0;
}

.txt-trunc {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.req.fold .hide-extra-content {
  height: 150px;
  // padding-bottom: 40px;
  position: relative;
  overflow: hidden;
}
.req.fold .hide-extra-content .req-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  background: rgb(243, 246, 249);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1111;
  display: block;
}
.req.fold .more {
  display: block;
}
.req .hide-extra-content {
  height: auto;
}
.req .hide-extra-content .req-overlay {
  display: none;
}
.req .more {
  display: none;
}

@import "./stepper.scss";

.cursor-pointer {
  cursor: pointer;
}

.excel-download {
  padding: 12px 32px 14px;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 60px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}
.excel-download:hover {
  opacity: 0.75;
  transition: all 0.3s ease;
}
.hover-opacity {
  transition: all 0.3s ease;
}
.hover-opacity:hover {
  opacity: 0.75;
  transition: all 0.3s ease;
}

.approcess {
  display: flex;
  margin: 60px 0;
  position: relative;
}
.approcess .sect {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #bbe1c4;
  min-height: 220px;
  border: 5px solid #bbe1c4;
  border-radius: 3px;
  color: #149124;
  cursor: default;
}
.approcess .sect.active {
  background-color: #ffe2a9;
  border: 5px solid #fbb326;
  color: #cc8800;
  animation: glowing 1.5s infinite;
}
.approcess .sect.active div small.aby {
  background-color: #cc8800 !important;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #ffd47e;
  }
  40% {
    box-shadow: 0 0 20px #ffd47e;
  }
  60% {
    box-shadow: 0 0 20px #ffd47e;
  }
  100% {
    box-shadow: 0 0 -10px #ffd47e;
  }
}

.approcess .sect .arr {
  position: absolute;
  width: 100%;
  left: 50%;
  z-index: 1;
}
.approcess .sect .arr.top {
  top: -50px;
}
.approcess .sect .arr.bottom {
  bottom: -50px;
}
.approcess .sect .step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.approcess .sect .step div {
  color: #fff;
  padding: 5px;
  border-radius: 20px;
  width: 28px;
  height: 28px;
}
.approcess .sect .step div {
  background-color: #149124;
}
.approcess .sect.active .step div {
  background-color: #cc8800;
}

.approcess .close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 1);
}
.navbar {
  padding: 0 1rem;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.9rem 0.7rem;
  }
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:focus {
    background: #dab317;
  }
}
